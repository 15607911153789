var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Cancel Tax Invoice"}},[_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
      _vm.detail ? _vm.detailShow + 'md:w-1/3 w-full mb-base' : '',
      _vm.detailHide,
    ]},[_c('vs-button',{attrs:{"color":"primary","icon-pack":"feather","icon":"icon-inbox"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Submit")]),_c('vs-table',{attrs:{"search":"","stripe":"","border":"","description":"","sst":true,"data":_vm.table.data,"max-items":_vm.table.length,"total":_vm.table.total},on:{"search":_vm.handleSearch,"change-page":_vm.handleChangePage},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,class:data[indextr].class,attrs:{"data":tr}},[_c('vs-td',{staticClass:"whitespace-no-wrap"},[[_c('vx-tooltip',{attrs:{"text":"Show"}},[_c('vs-button',{attrs:{"type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEdit(tr, indextr)}}})],1)]],2),_c('vs-td',[_c('vs-checkbox',{attrs:{"checked":_vm.id_approve.includes(tr.ID),"color":"primary"},on:{"click":function($event){return _vm.approve(
                  indextr,
                  data[indextr].ID
                )}}})],1),_c('vs-td',[_c('vs-checkbox',{attrs:{"checked":_vm.id_reject.includes(tr.ID),"color":"warning"},on:{"click":function($event){return _vm.reject(
                  indextr,
                  data[indextr].ID
                )}}})],1),_c('vs-td',{attrs:{"data":data[indextr].supplier_name}},[_c('div',{staticClass:"vx-row ml-0"},[_c('span',{staticClass:"w-5/5"},[_vm._v(_vm._s(data[indextr].TaxNumber))])])]),_c('vs-td',[_vm._v(" "+_vm._s(data[indextr].TrxCode)+" ")])],1)})}}])},[_c('template',{slot:"header"},[_c('vs-dropdown',{staticClass:"cursor-pointer",attrs:{"vs-trigger-click":""}},[_c('div',{staticClass:"p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(this.table.start)+" - "+_vm._s(this.table.end)+" of "+_vm._s(this.table.total))]),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"}})],1),_c('vs-dropdown-menu',_vm._l((_vm.table.limits),function(item){return _c('vs-dropdown-item',{key:item,on:{"click":function($event){return _vm.handleChangelength(item)}}},[_c('span',[_vm._v(_vm._s(item))])])}),1)],1)],1),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"width":"5%"}}),_c('vs-th',[_c('vs-checkbox',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.approveAll()}},model:{value:(_vm.statusApproveAll),callback:function ($$v) {_vm.statusApproveAll=$$v},expression:"statusApproveAll"}},[_vm._v("Approve All")])],1),_c('vs-th',[_c('vs-checkbox',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.rejectAll()}},model:{value:(_vm.statusRejectAll),callback:function ($$v) {_vm.statusRejectAll=$$v},expression:"statusRejectAll"}},[_vm._v("Reject All")])],1),_c('vs-th',[_vm._v("Tax Number")]),_c('vs-th',[_vm._v("Invoice Code")])],1)],2),_c('vs-pagination',{staticStyle:{"padding-top":"5px"},attrs:{"total":_vm.table.totalPage},model:{value:(_vm.setPage),callback:function ($$v) {_vm.setPage=$$v},expression:"setPage"}})],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
        _vm.detail ? _vm.detailShow + 'md:w-2/3 w-full mb-base' : '',
        _vm.detailHide,
      ]},[_c('vs-row',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{staticClass:"ml-auto",attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}},[_vm._v("Close")])],1),_c('vs-col',{staticClass:"vertical-divider",attrs:{"vs-offset":"0","vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"1"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.handleClose}})],1),_c('detail',{attrs:{"dn_id":_vm.dn_id,"dn_code":_vm.dn_code,"cn_number":_vm.cn_number,"cn_date":_vm.cn_date,"tr_number":_vm.tr_number,"tr_date":_vm.tr_date,"notes":_vm.notes},on:{"close":_vm.handleClose}})],1)],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }